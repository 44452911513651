import React from "react";

const Footer = () => {
  return (
    <div className="bg-black  ">
      <div className="text-white d-flex flex-column h-100 align-items-center pt-3 ">
        <p>&copy; 2024 All Rights Reserved | Star Enterprises Solutions</p>
      </div>
    </div>
  );
};

export default Footer;
