import React from "react";
import Layout from "../components/Layout";

const Collection = () => {
  return (
    <Layout>
      <div>This is Collection Page </div>
    </Layout>
  );
};

export default Collection;
