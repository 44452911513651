import React from "react";
import Layout from "../components/Layout";
import Office2 from "../image/Office2.jpg";
import "../styles/AboutPage.css"; // Import your CSS file

const Contact = () => {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Office2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    color: "white", // Ensures the text is visible against the background
    padding: "20px", // Add padding to improve readability
  };
  return (
    <Layout>
      {/* <div className="contact-container" style={backgroundStyle}>
        <h1>Contact Us</h1>
        <p>
          For inquiries, partnership opportunities, or any other questions,
          please feel free to reach out to us using the contact information
          provided below.
        </p>


        <div className="contact-info">
          <h2>Email</h2>


          <p>info@starenterprises.com</p>
          <h2>Phone</h2>
          <p>+123 456 7890</p>
          <h2>Address</h2>
          <p>123 Street, City, Country</p>
        </div>
      </div> */}
      {/* Above is old content  */}
      <div className="about-container" style={backgroundStyle}>
        <h1>Contact Us</h1>
        <p>
          For inquiries, partnership opportunities, or any other questions,
          please feel free to reach out to us using the contact information
          provided below.
        </p>

        <h2>Email</h2>
        <div class="container overflow-hidden text-center">
          <div class="row gy-5">
            <div class="col-6">
              <p>
                <h3>Collection</h3>Collection@StarEntErprisesSolutions.com
              </p>
            </div>
            <div class="col-6">
              <p>
                <h3>IT Services</h3>ITServices@starEnterprisesSolutions.com
              </p>
            </div>
            <div class="col-6">
              <p>
                <h3>Complaint</h3>Complaint@StarEnterprisesSolutions.com
              </p>
            </div>
            <div class="col-6">
              <p>
                <h3>Feedback</h3>Feedback@StarEnterprisesSolutions.com
              </p>
            </div>
          </div>
        </div>

        <h2>Address</h2>
        <p>
          Star Enterprises,C/O CTS NO.20260/5 Shree Ram Shobha Opp Sai Hospital
          Near ware class Samarth Nagar Aurangabad-431001
        </p>
      </div>
    </Layout>
  );
};

export default Contact;
