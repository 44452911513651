import React from "react";
import Layout from "../components/Layout";

const login = () => {
  return (
    <Layout>
      <h1>I am a LogIn page</h1>
    </Layout>
  );
};

export default login;
