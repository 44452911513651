import React from "react";
import Layout from "../components/Layout";
import Office2 from "../image/Office2.jpg";
import "../styles/AboutPage.css"; // Import your CSS file

const About = () => {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Office2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    color: "white", // Ensures the text is visible against the background
    padding: "20px", // Add padding to improve readability
  };
  return (
    <Layout>
      <div className="about-container" style={backgroundStyle}>
        <h1>About Us</h1>
        <p>
          Star Enterprises Solutions is a leading provider of bank recovery
          services and innovative software solutions. Our mission is to deliver
          efficient and effective recovery services while driving innovation
          through tailor-made software applications.
        </p>
        <h2>Our Services</h2>
        <p>
          We specialize in reclaiming assets related to credit cards, home
          loans, car loans, and various other forms of bank credit. In addition,
          we develop custom software and computer applications to optimize
          business operations and streamline processes.
        </p>
        <h2>Our Mission</h2>
        <p>
          At Star Enterprises Solutions, our mission is to blend industry
          expertise with technological innovation to deliver results that
          matter. We are dedicated to empowering enterprises to achieve greater
          efficiency and productivity through our services and solutions.
        </p>
      </div>
    </Layout>
  );
};

export default About;