import React from "react";
import Layout from "../components/Layout";
import Office2 from "../image/Office2.jpg";
import "../styles/HomePage.css"; // Import your CSS file

const Home = () => {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${Office2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    color: "white", // Ensures the text is visible against the background
    padding: "20px", // Add padding to improve readability
  };
  return (
    <Layout>
      <div style={backgroundStyle} className="text-white content-container">
        <h1 className="company-name">Star Enterprises Solutions</h1>

        <p>
          Welcome to Star Enterprises Solutions, your trusted partner in
          comprehensive bank recovery services and cutting-edge software
          solutions.
        </p>

        <p>
          At Star Enterprises Solutions, we specialize in bank recovery,
          offering expertise in reclaiming assets related to credit cards, home
          loans, car loans, 2-wheeler loans, and various other forms of bank
          credit. With a strong track record and a dedication to excellence, we
          work closely with esteemed institutions such as ICICI Bank, HDFC Bank,
          Axis Bank, and many more, ensuring efficient and effective recovery
          solutions.
        </p>
        <p>
          In addition to our bank recovery services, we are committed to driving
          innovation through the development of tailor-made software and
          computer applications designed to streamline and optimize business
          operations. Our team is dedicated to crafting solutions that meet the
          specific needs of your enterprise, empowering you to achieve greater
          efficiency and productivity.
        </p>
        <p>
          At Star Enterprises Solutions, we blend industry expertise with
          technological innovation to deliver results that matter. Partner with
          us to experience the difference in bank recovery and software
          solutions.
        </p>
      </div>
    </Layout>
  );
};

export default Home;
