import React from "react";
import logo from "../image/star.gif";
import { NavLink } from "react-router-dom";
import "../styles/HeaderStyle.css";
const Header = () => {
  return (
    <div>
      <div className="bg-black">
        <nav className="navbar navbar-expand-lg  px-5 py-1 ">
          <img src={logo} alt="Star" />
          <ul className="navigation-menu my-1">
            <li>
              <NavLink activeclassname="active" to={"/"}>
                Home
              </NavLink>
            </li>
            {/* <li>
              <NavLink to={"/ITServices"}>IT Services</NavLink>
            </li> */}
            {/* <li>
              <NavLink to={"/Collection"}>Collection</NavLink>
            </li> */}

            <li className="float-end">
              <NavLink to={"/About"}>About</NavLink>
            </li>
            <li>
              <NavLink to={"/Contact"}>Contact</NavLink>
            </li>

            {/* <li className="float-end">
              <NavLink to={"/login"}>Log In</NavLink>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
